<template>
  <div class="content">
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
            <div v-if="filtered" class="card-body p-0">
              <div v-if="fetched" class="table-responsive">
                <table
                  v-if="Object.keys(insights).length"
                  class="table table-striped"
                >
                  <thead>
                    <tr>
                      <th scope="col">{{ $tc('generic-str.acc', 1) }}</th>
                      <th scope="col">Razão Social</th>
                      <th scope="col">
                        {{ $tc('generic-str.status.lbl-pending', 1) }}
                      </th>
                      <th scope="col">{{ $tc('generic-str.sent-a', 2) }}</th>
                      <th scope="col">{{ $tc('generic-str.delivered', 2) }}</th>
                      <th scope="col">
                        {{ $tc('generic-str.unavailable', 2) }}
                      </th>
                      <th scope="col">{{ $tc('generic-str.failure', 2) }}</th>
                      <th scope="col">
                        {{ $tc('generic-str.status.lbl-response', 2) }}
                      </th>
                      <th scope="col">
                        {{ $tc('generic-str.tariffed-o', 1) }}
                      </th>
                      <th scope="col">%DLR</th>
                      <th scope="col">%DR</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><b>TOTAL</b></td>
                      <td></td>
                      <td>{{ total.accepted }}</td>
                      <td>{{ total.sent }}</td>
                      <td>{{ total.delivered }}</td>
                      <td>{{ total.undelivered }}</td>
                      <td>{{ total.failed }}</td>
                      <td>{{ total.received }}</td>
                      <td>{{ total.tariffed }}</td>
                      <td>
                        {{
                          (
                            ((total.delivered +
                              total.undelivered) *
                              100) /
                            (total.tariffed - total.received)
                          ).toFixed(2)
                        }}
                      </td>
                      <td>
                        {{
                          (
                            ((total.delivered) *
                              100) /
                            (total.tariffed - total.received)
                          ).toFixed(2)
                        }}
                      </td>
                    </tr>
                    <tr v-for="(insight, id) in insights" :key="id">
                      <td>{{ insight.account_name }}</td>
                      <td>{{ insight.company_name }}</td>
                      <td>{{ insight.accepted }}</td>
                      <td>{{ insight.sent }}</td>
                      <td>{{ insight.delivered }}</td>
                      <td>
                        {{ insight.undelivered }}
                      </td>
                      <td>{{ insight.failed }}</td>
                      <td>{{ insight.received }}</td>
                      <td>{{ insight.tariffed }}</td>
                      <td>
                        {{
                          (
                            ((insight.delivered +
                              insight.undelivered) *
                              100) /
                            (insight.tariffed - insight.received)
                          ).toFixed(2)
                        }}
                      </td>
                      <td>
                        {{
                          (
                            ((insight.delivered) *
                              100) /
                            (insight.tariffed - insight.received)
                          ).toFixed(2)
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="text-center table-placeholder">
                  <i class="fas fa-envelope font-size-80"></i>
                  <h5 class="card-title m-t-20">{{$t('sms-report-component.none')}}</h5>
                </div>
              </div>
              <div v-else class="static qt-block-ui" style="padding: 120px" />
            </div>
            <div v-else class="text-center table-placeholder">
              <i class="fas fa-envelope font-size-80"></i>
              <h5 class="card-title m-t-20">{{$t('sms-report-component.none')}}</h5>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import CustomerService from '@/services/customer.service';
import SmsService from '@/services/admin/sms.service';
// import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'SmsDashboard',
  components: {
    // PageHeader,
  },
  props: ['start-date', 'end-date', 'filters', 'is-filtered'],
  data() {
    return {
      fetched: false,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      filtered: false,
      customers: [],
      insights: [],
      total: {},
      beginDate: '',
      endDate: '',
      form: {
        account_id: {
          in: [],
        },
        scheduled_at: {
          gte: '',
          lte: '',
        },
      },
    };
  },
  created() {
    this.fetchCustomers();
    this.fetch();
  },
  methods: {
    fetchCustomers() {
      CustomerService.getCustomers().then(
        (customers) => {
          this.customers = customers;
          this.customers.push(this.$store.state.account);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetch() {
      if (!this.filtered) this.filtered = true;
      this.fetched = false;
      this.form.account_id.in = this.filters.account_id.in;
      this.form.scheduled_at.gte = this.startDate;
      this.form.scheduled_at.lte = this.endDate;
      SmsService.getInsights(this.form).then(
        (insights) => {
          this.total = {
            accepted: 0,
            sent: 0,
            delivered: 0,
            undelivered: 0,
            failed: 0,
            received: 0,
            tariffed: 0,
          };
          this.insights = insights;
          Object.values(insights).forEach((insight, i) => {
            insight.tariffed = 0;
            if (!insight.accepted) insight.accepted = 0;
            if (!insight.sent) insight.sent = 0;
            if (!insight.delivered) insight.delivered = 0;
            if (!insight.undelivered) insight.undelivered = 0;
            if (!insight.failed) insight.failed = 0;
            if (!insight.received) insight.received = 0;
            Object.entries(insight).forEach((array) => {
              if (array[0] !== 'account_name' && array[0] !== 'company_name') {
                this.total[array[0]] += array[1];
                if (array[0] !== 'failed') {
                  insight.tariffed += array[1];
                }
              }
            });
            this.total.tariffed += insight.tariffed;
          });
          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeDate(date) {
      if (date) {
        this.beginDate = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 00:00:00',
        );
        this.endDate = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 23:59:59',
        );
      } else {
        this.beginDate = moment().format('YYYY-MM-DD 00:00:00');
        this.endDate = moment().format('YYYY-MM-DD 23:59:59');
      }
      this.form.scheduled_at.gte = this.beginDate;
      this.form.scheduled_at.lte = this.endDate;
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
      // this.fetchAll();
    },
  },
};
</script>
